<template>
  <div>
    <CRow class=" justify-content-center">
      <CCol xs="12" lg="8">
        <CCard>
          <CCardHeader>
            <strong class="h5">Become An Agent</strong>
            <p>Please Provide The Details In Registering An Agent</p>
          </CCardHeader>
          <CCardBody>
            <CForm>
              <CRow
                class="justify-content-between"
                align-horizontal="center"
                align-vertical="center"
              >
                <CCol sm="12" md="12" lg="6">
                  <CInput
                    label="First Name"
                    v-model="agent.first_name"
                    description="Enter The First Name Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="6">
                  <CInput
                    label="Last Name"
                    v-model="agent.last_name"
                    description="Enter The Last Name Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="12">
                  <CInput
                    label="Email Address"
                    v-model="agent.email"
                    type="email"
                    description="Enter The Email Address Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="6">
                  <CInput
                    label="Phone Number"
                    v-model="agent.phone_number"
                    description="Enter The Phone Number Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="6">
                  <CInput
                    label="BVN"
                    v-model="agent.bvn"
                    description="Enter The Bank Verification Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="12">
                  <CInput
                    label="Business Name"
                    v-model="agent.business_name"
                    description="Enter The Business Name Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="12">
                  <CInput
                    label="Address"
                    v-model="agent.address"
                    description="Enter The Address Of The Agent"
                  />
                </CCol>
                <CCol sm="12" md="12" lg="6">
                  <label for="state">State</label>
                  <select
                    name="state"
                    class="form-control"
                    v-model="agent.state"
                  >
                    <option value="" selected>Select State</option>
                    <option
                      v-for="state in states"
                      :key="state.state.id"
                      :value="state.state.id"
                    >
                      {{ state.state.name }}
                    </option>
                  </select>
                </CCol>
                <CCol sm="12" md="12" lg="6">
                  <CInput label="City" v-model="agent.city" />
                </CCol>

                <CCol sm="12" md="12" lg="6">
                  <CImg v-if="agent.utility_bill" />
                  <input
                    type="file"
                    class="upload"
                    ref="fileInput"
                    accept="image/*"
                    style="display: none;"
                    @change="onFilePicked"
                  />
                  <CButton color="primary">
                    <CIcon name="cil-camera" class="primary" size="xl" />
                    Upload Utility Bill
                  </CButton>
                </CCol>
                <CCol sm="12" md="12" lg="6">
                  <CImg v-if="agent.id" />
                  <input
                    type="file"
                    class="upload"
                    accept="image/*"
                    style="display: none;"
                    @change="onFilePicked"
                  />
                  <CButton color="primary">
                    <CIcon name="cil-camera" class="primary" size="xl" />
                    Upload ID
                  </CButton>
                </CCol>
                <CButton
                  color="primary"
                  variant="outline"
                  class="px-4 float-right mt-4"
                  type="submit"
                  size="lg"
                >
                  <!-- <span v-if="loading"
                    ><loader-icon size="1.5x" class="custom-class"></loader-icon
                    >Please Wait</span
                  > -->
                  <span>Add Terminal Agent</span>
                </CButton>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import States from "@/states";
export default {
  name: "BecomeAnAgent",
  data() {
    return {
      agent: {
        first_name: "",
        last_name: "",
        utility_bill: "",
        id: "",
      },
      states: States,
    };
  },
  methods: {},
};
</script>

<style scoped></style>
